import React from 'react';
import '../styles/Valores.css'; // Asegúrate de tener este archivo CSS

// Imágenes para cada valor (asegúrate de que las rutas sean correctas)
import integridadImg from '../assets/img/integridad.jpg';
import innovacionImg from '../assets/img/innovacion.jpg';
import compromisoImg from '../assets/img/compromiso.jpg';
import calidadImg from '../assets/img/calidad.jpg';
import responsabilidadImg from '../assets/img/responsabilidad.jpg';
import transparenciaImg from '../assets/img/transparencia.jpg';

const Valores = () => {
  return (
    <div className="valores-container section">
      <h1>Valores</h1>
      <div className="valores-cards">
        <div className="valores-card">
          <img src={integridadImg} alt="Integridad" />
          <h2>Integridad</h2>
          <p>Actuar con honestidad y ética en todas nuestras operaciones.</p>
        </div>
        <div className="valores-card">
          <img src={innovacionImg} alt="Innovación" />
          <h2>Innovación</h2>
          <p>Buscar y aplicar nuevas soluciones para mejorar la salud y el bienestar.</p>
        </div>
        <div className="valores-card">
          <img src={compromisoImg} alt="Compromiso" />
          <h2>Compromiso</h2>
          <p>Dedicar nuestros esfuerzos a satisfacer las necesidades de nuestros clientes.</p>
        </div>
        <div className="valores-card">
          <img src={calidadImg} alt="Calidad" />
          <h2>Calidad</h2>
          <p>Mantener los más altos estándares en nuestros productos y servicios.</p>
        </div>
        <div className="valores-card">
          <img src={responsabilidadImg} alt="Responsabilidad" />
          <h2>Responsabilidad</h2>
          <p>Asumir el compromiso con la comunidad y el medio ambiente.</p>
        </div>
        <div className="valores-card">
          <img src={transparenciaImg} alt="Transparencia" />
          <h2>Transparencia</h2>
          <p>Operar de manera abierta y clara, fomentando la confianza.</p>
        </div>
      </div>
    </div>
  );
};

export default Valores;