// src/components/Medicos.js
import React from 'react';
import '../styles/Medicos.css'; // Asegúrate de que el CSS esté bien estructurado

// Datos de médicos para Beryn Farmacéutica
const medicosData = [
  {
    name: 'Dr.',
    specialty: 'Dermatologìa',
    image: require('../assets/img/derma.png'), 
    description: 'Dr. Hernández se especializa en el diagnóstico y tratamiento de enfermedades cardíacas.'
  },
  {
    name: 'Dra. Elena Ruiz',
    specialty: 'Neurología',
    image: require('../assets/img/nutricion.png'), 
    description: 'Dra. Ruiz ofrece atención especializada en trastornos neurológicos y enfermedades del sistema nervioso.'
  },
  {
    name: 'Dr. Javier Morales',
    specialty: 'Endocrinología',
    image: require('../assets/img/especialista.png'), 
    description: 'Dr. Morales maneja el tratamiento de enfermedades relacionadas con las glándulas endocrinas.'
  },
  
  // Puedes agregar más médicos si es necesario
];

const Medicos = () => {
  return (
    <div className="medicos-container">
      <h1>Conoce a Nuestros Médicos</h1>
      <div className="medicos-list">
        {medicosData.map((medico, index) => (
          <div className="medico-card" key={index}>
            <img src={medico.image} alt={medico.name} className="medico-image" />
            <div className="medico-info">
              <h2 className="medico-name">{medico.name}</h2>
              <p className="medico-specialty">{medico.specialty}</p>
              <p className="medico-description">{medico.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Medicos;