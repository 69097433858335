import React from 'react';
import '../styles/Noticias.css';
import imagen from '../assets/img/DISEÑO ORIGINAL.jpg';

const Noticia1 = () => (
  <div className="noticia-standard">
    <div className="noticia-image-container">
      <img src={imagen} alt="Imagen de la noticia 1" className="noticia-image" />
    </div>
    <div className="noticia-info-container">
      <div className="noticia-info">
        <h2 className="noticia-standard-title">La Importancia de la Microbiota en la Salud Humana: Un Enfoque desde Beryn Farmacéutica</h2>
        <p className="noticia-standard-description">En Beryn Farmacéutica, entendemos que la salud humana es un ecosistema complejo donde la microbiota juega un papel fundamental.</p>
        <div className="noticia-content">
          <p>La microbiota, el conjunto de microorganismos que habitan en nuestro cuerpo, es esencial para mantener el equilibrio y la salud general.</p>
          <p>A continuación, exploramos cómo la microbiota influye en diversos aspectos de nuestra salud y cómo nuestras investigaciones y productos están diseñados para apoyar este delicado equilibrio.</p>
          <h3>La Microbiota: Un Aliado Invisible</h3>
          <p>La microbiota se compone de bacterias, virus, hongos y otros microorganismos que residen principalmente en el intestino, pero también en la piel, la boca y otras partes del cuerpo.</p>
          <h3>Técnicas de Caracterización y Transferencia</h3>
          <p>Gracias a las técnicas de secuenciación masiva del gen 16S ARNr, hemos avanzado significativamente en la comprensión de la composición y función de la microbiota.</p>
          <h3>Microbiota Intestinal: El Guardián de Nuestra Salud</h3>
          <p>La microbiota intestinal es especialmente importante, ya que desempeña roles clave en la modulación del sistema inmune, la regulación del sistema nervioso, la síntesis de vitaminas y la defensa del intestino.</p>
          <h3>El Eje Microbiota-Intestino-Cerebro</h3>
          <p>Investigaciones recientes han revelado un complejo intercambio de mensajes entre el intestino y el cerebro, conocido como el eje microbiota-intestino-cerebro.</p>
          <h3>Nuestro Compromiso en Beryn Farmacéutica</h3>
          <p>En Beryn Farmacéutica, estamos comprometidos con la investigación y el desarrollo de productos que apoyen la salud de la microbiota.</p>
          <h3>Conclusión</h3>
          <p>La microbiota es un componente esencial de nuestra salud, y en Beryn Farmacéutica, estamos dedicados a entender y apoyar este ecosistema invisible.</p>
        </div>
        <span className="noticia-date">Publicado el 6 de septiembre de 2024</span>
        <button className="noticia-standard-button">Leer más</button>
      </div>
    </div>
  </div>
);

export default Noticia1;