// src/components/Colaboradores.js
import React from 'react';
import '../styles/Colaboradores.css'; // Asegúrate de que el CSS esté bien estructurado

// Datos de colaboradores para Beryn Farmacéutica
const colaboradoresData = [
  {
    name: '',
    role: 'Jefe de Investigación y Desarrollo',
    image: require('../assets/img/doct.png'), // Ruta de la imagen
    description: 'El Dr lidera el equipo de investigación para el desarrollo de nuevos medicamentos y tratamientos.'
  },
  {
    name: '',
    role: 'Gerente de Operaciones y Control de Calidad',
    image: require('../assets/img/doc2.png'),
    description: 'Fredy se encarga de asegurar que todos los productos cumplan con los más altos estándares de calidad.'
  },
  {
    name: '',
    role: 'Gerente de Marketing y Desarrollo Digital ',
    image: require('../assets/img/mec3.png'),
    description: ' El Ingeniero Alejandro desarrolla y ejecuta estrategias de marketing para promover los productos farmacéuticos de Beryn.'
  },
  
  // Puedes agregar más colaboradores si es necesario
];

const Colaboradores = () => {
  return (
    <div className="proveedores-container">
      <h1>Nuestros Colaboradores</h1>
      <div className="proveedores-slider">
        {colaboradoresData.map((colaborador, index) => (
          <div className="proveedor-card" key={index}>
            <img src={colaborador.image} alt={colaborador.name} className="proveedor-image" />
            <div className="proveedor-info">
              <h2 className="proveedor-name">{colaborador.name}</h2>
              <p className="proveedor-role">{colaborador.role}</p>
              <p className="proveedor-description">{colaborador.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Colaboradores;