import React from 'react';

const Analgesicos = () => (
  <div>
    <h2>Analgesicos</h2>
    <p>Descripción de los Analgesicos...</p>
  </div>
);

export default Analgesicos;
