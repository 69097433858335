import React from 'react';

const Antiinflamatorios = () => (
  <div>
    <h2>Antiinflamatorios</h2>
    <p>Descripción de los Antiinflamatorios...</p>
  </div>
);

export default Antiinflamatorios;
