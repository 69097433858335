import React from 'react';
import '../styles/servicios.css';

const Consultoria = () => {
  return (
    <div className="servicios-section">
      <div className="consultoria-card servicio-card">
        <h2 className="proveedor-name">Consultoría Farmacéutica</h2>
        <p className="proveedor-description">
          Beryn Farmacéutica ofrece servicios de consultoría especializados para ayudar a las empresas farmacéuticas a navegar el complejo panorama regulatorio, optimizar el desarrollo de productos y mejorar la eficiencia operativa.
        </p>
        <ul className="proveedor-list">
          <li>Estrategia e implementación de cumplimiento regulatorio.</li>
          <li>Desarrollo de sistemas de gestión de calidad y preparación para auditorías.</li>
          <li>Gestión del ciclo de vida del producto, incluyendo estudios de formulación y estabilidad.</li>
          <li>Estrategias de evaluación y mitigación de riesgos para ensayos clínicos y lanzamientos de productos.</li>
        </ul>
      </div>

      <div className="testimonios-section">
        <h2 className="section-title">Testimonios</h2>
        <div className="testimonios-cards">
          <div className="testimonial-card">
            <p className="testimonial-text">
              "Beryn Farmacéutica transformó nuestra operación. Su enfoque en el cumplimiento regulatorio nos permitió lanzar productos con total confianza."
            </p>
            <p className="testimonial-author">- María Gutiérrez, CEO de Pharma Solutions</p>
          </div>
          <div className="testimonial-card">
            <p className="testimonial-text">
              "La asesoría que recibimos en gestión de calidad fue excepcional. Estamos mejor preparados para auditorías y lanzamientos futuros."
            </p>
            <p className="testimonial-author">- Juan Pérez, Director de Calidad en MedTech</p>
          </div>
        </div>
      </div>

      <div className="casos-exito-section">
        <h2 className="section-title">Casos de Éxito</h2>
        <div className="casos-cards">
          <div className="caso-card">
            <h3 className="caso-title">Optimización de la Producción en Pharma Co.</h3>
            <p className="caso-description">
              Ayudamos a Pharma Co. a reducir sus tiempos de producción en un 30% mediante la implementación de un sistema de gestión de calidad mejorado.
            </p>
          </div>
          <div className="caso-card">
            <h3 className="caso-title">Cumplimiento Regulatorio para MedLine</h3>
            <p className="caso-description">
              Guiamos a MedLine a través del proceso regulatorio de la FDA, logrando la aprobación de su nuevo producto en tiempo récord.
            </p>
          </div>
        </div>
      </div>

      <div className="contacto-section">
        <h2 className="section-title">Contáctenos</h2>
        <form className="contact-form">
          <input type="text" placeholder="Nombre" required />
          <input type="email" placeholder="Correo Electrónico" required />
          <textarea placeholder="Mensaje" required></textarea>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default Consultoria;
