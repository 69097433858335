import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css'; // Asegúrate de que el CSS esté bien estructurado
import logo from '../assets/img/Logo.png'; // Asegúrate de que la ruta sea correcta

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Maneja el estado del menú en dispositivos móviles
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Cierra el menú cuando se selecciona un elemento de navegación
  const handleNavItemClick = () => {
    setIsMenuOpen(false);
  };

  // Función para manejar la búsqueda cuando se presiona Enter
  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const searchTerm = e.target.value.trim(); // Obtener el término de búsqueda
      if (searchTerm !== '') {
        console.log("Buscando:", searchTerm);
        // Lógica para manejar la búsqueda
        searchItems(searchTerm).then((results) => {
          console.log("Resultados:", results);
        }).catch((error) => {
          console.error("Error al buscar:", error);
        });
      } else {
        console.log("Por favor, ingresa un término de búsqueda");
      }
    }
  };

  // Ejemplo de función para buscar items
  const searchItems = (searchTerm) => {
    return fetch(`/api/search?q=${searchTerm}`)
      .then((response) => response.json())
      .then((data) => data.results);
  };

  // Elementos de navegación principales
  const navItems = [
    { label: 'Principal', href: '/' },
    { label: 'Colaboradores', href: '/colaboradores' },
    { label: 'Médicos', href: '/medicos' },
    { label: 'Clientes', href: '/clientes' },
    { label: 'Vacantes', href: '/vacantes' },
    { label: 'Proveedores', href: '/proveedores' },
  ];

  // Elementos del menú desplegable
  const dropdownItems = [
    {
      label: 'Quiénes Somos',
      items: [
        { label: 'Sobre la Empresa', href: '/quienes-somos/1' },
        { label: 'Historia', href: '/quienes-somos/2' },
        { label: 'Visión', href: '/quienes-somos/3' },
        { label: 'Misión', href: '/quienes-somos/4' },
        { label: 'Valores', href: '/quienes-somos/5' },
        { label: 'Certificaciones', href: '/quienes-somos/6' },
        { label: 'Recomendaciones', href: '/quienes-somos/7' },
      ],
    },
    {
      label: 'Servicios',
      items: [
        { label: 'Consultoría Farmacéutica', href: '/servicios/consultoria' },
        { label: 'Desarrollo de Productos', href: '/servicios/desarrollo' },
        { label: 'Distribución y Logística', href: '/servicios/logistica' },
       
      ],
    },
    {
      label: 'Productos',
      items: [
        { label: 'Analgesicos', href: '/productos/analgesicos' },
        { label: 'Antiinflamatorios', href: '/productos/antiinflamatorios' },
        { label: 'Antibióticos', href: '/productos/antibioticos' },
        { label: 'Vitaminas', href: '/productos/vitaminas' },
        { label: 'Minerales', href: '/productos/minerales' },
      ],
    },
    {
      label: 'Noticias',
      items: [
        { label: 'Noticia 1', href: '/noticias/1' },
        { label: 'Noticia 2', href: '/noticias/2' },
        { label: 'Noticia 3', href: '/noticias/3' },
      ],
    },
    {
      label: 'Contáctanos',
      items: [
        { label: 'Formulario', href: '/contactanos/1' },
        { label: 'Localización', href: '/contactanos/2' },
        { label: 'Redes Sociales', href: '/contactanos/3' },
      ],
    },
  ];

  return (
    <>
      {/* Navbar principal */}
      <nav className="navbar navbar-primary navbar-expand-lg">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <button
          className={`navbar-toggler ${isMenuOpen ? 'open' : ''}`}
          type="button"
          onClick={handleMenuToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
          <ul className="navbar-nav ml-auto">
            {navItems.map((item, index) => (
              <li className="nav-item" key={index}>
                <Link
                  className="nav-link"
                  to={item.href}
                  onClick={handleNavItemClick}
                >
                  {item.label}
                </Link>
              </li>
            ))}
            <li className="nav-item search-container">
              <div className="search-wrapper">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Buscar"
                  onKeyDown={handleSearch}
                />
                <i className="fas fa-search search-icon"></i>
              </div>
            </li>
            <Dropdown>
              <Dropdown.Toggle className="language-toggle">
                Language
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">English</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Español</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Français</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </nav>

      {/* Segunda navbar */}
      <nav className="navbar navbar-secondary navbar-expand-lg">
        <div className="collapse navbar-collapse show">
          <ul className="navbar-nav mx-auto">
            {dropdownItems.map((dropdown, index) => (
              <Dropdown key={index}>
                <Dropdown.Toggle
                  className="nav-link dropdown-toggle"
                  as={Link}
                  to="#"
                >
                  {dropdown.label}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  {dropdown.items.map((item, subIndex) => (
                    <Dropdown.Item
                      key={subIndex}
                      as={Link}
                      to={item.href}
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};



export default Navbar;

