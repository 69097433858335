// src/components/Certificaciones.js
import React from 'react';

const Certificaciones = () => {
  return (
    <div className="section">
      <h1>Certificaciones</h1>
      <p>
        Contamos con diversas certificaciones que garantizan la calidad y seguridad de nuestros productos. Entre ellas, se incluyen:
      </p>
      <ul>
        <li>Certificación GMP (Buenas Prácticas de Manufactura)</li>
       
      </ul>
    </div>
  );
};

export default Certificaciones;
