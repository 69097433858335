import React from 'react';

const Minerales = () => (
  <div>
    <h2>Minerales</h2>
    <p>Descripción de los Minerales...</p>
  </div>
);

export default Minerales;
