import React from 'react';

const Antibioticos = () => (
  <div>
    <h2>Antibióticos</h2>
    <p>Descripción de los Antibióticos...</p>
  </div>
);

export default Antibioticos;
