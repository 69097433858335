import React from 'react';
import Slider from 'react-slick';
import '../styles/Proveedores.css'; // Asegúrate de que el CSS esté bien estructurado

// Datos de proveedores
const proveedoresData = [
  {
    name: 'Proveedor A',
    image: require('../assets/img/compromiso.jpg'), 
    description: 'Proveedor A ofrece soluciones innovadoras en tecnología farmacéutica.',
    categories: ['Tecnología', 'Innovación'],
    testimonial: 'Proveedor A ha sido un socio clave en nuestro crecimiento.',
    website: 'https://proveedora.com',
  },
  {
    name: 'Proveedor B',
    image: require('../assets/img/calidad.jpg'), 
    description: 'Proveedor B se especializa en suministros médicos de alta calidad.',
    categories: ['Suministros', 'Calidad'],
    testimonial: 'Proveedor B siempre entrega productos de primera clase.',
    website: 'https://proveedorb.com',
  },
  {
    name: 'Proveedor C',
    image: require('../assets/img/img1.jpg'), 
    description: 'Proveedor C brinda servicios de consultoría en la industria farmacéutica.',
    categories: ['Consultoría', 'Servicios'],
    testimonial: 'Proveedor C nos ha ayudado a mejorar nuestros procesos significativamente.',
    website: 'https://proveedorc.com',
  }
  // Puedes agregar más proveedores si es necesario
];

const Proveedores = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300, // Reducido para una transición más rápida
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Añadido para que el slider se desplace automáticamente
    autoplaySpeed: 2000, // Ajusta el intervalo entre transiciones automáticas
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="proveedores-container">
      <h1>Nuestros Proveedores</h1>
      <Slider {...settings} className="proveedores-slider">
        {proveedoresData.map((proveedor, index) => (
          <div className="proveedor-card" key={index}>
            <img src={proveedor.image} alt={proveedor.name} className="proveedor-image" />
            <div className="proveedor-info">
              <h2 className="proveedor-name">{proveedor.name}</h2>
              <p className="proveedor-description">{proveedor.description}</p>
              <div className="proveedor-categories">
                {proveedor.categories.map((category, idx) => (
                  <span className="proveedor-category" key={idx}>{category}</span>
                ))}
              </div>
              <p className="proveedor-testimonial">"{proveedor.testimonial}"</p>
              <a href={proveedor.website} className="proveedor-website" target="_blank" rel="noopener noreferrer">Visitar sitio web</a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Proveedores;
