import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';


import QuienesSomos from './components/QuienesSomos';
import SobreLaEmpresa from './components/SobreLaEmpresa';
import Historia from './components/Historia';
import Vision from './components/Vision';
import Mision from './components/Mision';
import Valores from './components/Valores';
import Certificaciones from './components/Certificaciones';
import Recomendaciones from './components/Recomendaciones';
import Colaboradores from './components/Colaboradores';
import Medicos from './components/Medicos';
import Clientes from './components/Clientes';
import Proveedores from './components/Proveedores';
import Vacantes from './components/Vacantes';
import Consultoria from './components/Consultoria';
import Desarrollo from './components/Desarrollo';
import Logistica from './components/Logistica';
import Analgesicos from './components/Analgesicos';
import Antiinflamatorios from './components/Antiinflamatorios';
import Antibioticos from './components/Antibioticos';
import Vitaminas from './components/Vitaminas';
import Minerales from './components/Minerales';
import Noticia1 from './components/Noticia1';
import Noticia2 from './components/Noticia2';
import Noticia3 from './components/Noticia3';


import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />

            </>
          } />
          <Route path="/quienes-somos" element={<QuienesSomos />} />
          <Route path="/quienes-somos/1" element={<SobreLaEmpresa />} />
          <Route path="/quienes-somos/2" element={<Historia />} />
          <Route path="/quienes-somos/3" element={<Vision />} />
          <Route path="/quienes-somos/4" element={<Mision />} />
          <Route path="/quienes-somos/5" element={<Valores />} />
          <Route path="/quienes-somos/6" element={<Certificaciones />} />
          <Route path="/quienes-somos/7" element={<Recomendaciones />} />
          <Route path="/quienes-somos/8" element={<Colaboradores />} />
          <Route path="/quienes-somos/9" element={<Medicos />} />
          <Route path="/quienes-somos/10" element={<Clientes />} />
          <Route path="/quienes-somos/11" element={<Proveedores />} />
          <Route path="/quienes-somos/12" element={<Vacantes />} />
          <Route path="/servicios/consultoria" element={<Consultoria />} />
          <Route path="/servicios/desarrollo" element={<Desarrollo />} />
          <Route path="/servicios/logistica" element={<Logistica />} />
          <Route path="/productos/analgesicos" element={<Analgesicos />} />
          <Route path="/productos/antiinflamatorios" element={<Antiinflamatorios />} />
          <Route path="/productos/antibioticos" element={<Antibioticos />} />
          <Route path="/productos/vitaminas" element={<Vitaminas />} />
          <Route path="/productos/minerales" element={<Minerales />} />
          <Route path="/noticias/1" element={<Noticia1 />} />
          <Route path="/noticias/2" element={<Noticia2 />} />
          <Route path="/noticias/3" element={<Noticia3 />} />
          <Route path="/colaboradores" element={<Colaboradores />} />
          <Route path="/medicos" element={<Medicos />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/vacantes" element={<Vacantes />} />
          <Route path="/proveedores" element={<Proveedores />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

