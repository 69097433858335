import React, { useState } from 'react';
import '../styles/SobreLaEmpresa.css';

const SobreLaEmpresa = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <div className="sobre-la-empresa-container">
      <div className="sobre-la-empresa-header">
        <h1>Sobre La Empresa</h1>
      </div>
      <div className="sobre-la-empresa-content">
     
        <div className={`empresa-content ${isContentVisible ? "show" : ""}`}>
          <h2>Historia de las Farmacias y Medicamentos en Panamá</h2>
          <p>
            La historia de las farmacias y medicamentos en Panamá se remonta a la época colonial, cuando los españoles establecieron las primeras farmacias en el país. A lo largo de los años, la industria farmacéutica ha evolucionado significativamente, con la llegada de nuevas tecnologías y la creación de empresas como Beryn Farmacéutica.
          </p>
          <p>
            En la actualidad, Panamá cuenta con una industria farmacéutica en constante crecimiento, con empresas que ofrecen una amplia variedad de productos y servicios. Beryn Farmacéutica se encuentra entre las empresas líderes en la región, gracias a su compromiso con la innovación y la calidad.
          </p>
          
        </div>
        <button className="btn-toggle" onClick={toggleContent}>
          {isContentVisible ? "Ocultar" : "Acerca de"}
        </button>
      </div>
      
    </div>
  );
};

export default SobreLaEmpresa;
