// src/components/Vacantes.js
import React from 'react';
import '../styles/Vacantes.css'; // Asegúrate de que el CSS esté bien estructurado

// Datos de vacantes para Beryn Farmacéutica
const vacantesData = [
  {
    title: 'Visitador Médico',
    location: 'Panamá, Panamá',
    type: 'Tiempo Completo',
    description: 'Buscamos un visitador médico con experiencia en promoción de productos farmacéuticos para atender a médicos y centros de salud.',
    applyLink: '#', // Enlace para postularse
},
{
    title: 'Especialista en Control de Medicamentos',
    location: 'Panamá, Panamá',
    type: 'Tiempo Completo',
    description: 'Se requiere un especialista en control de medicamentos con conocimientos en normativas farmacéuticas y manejo de inventarios.',
    applyLink: '#',
},

{
    title: 'Químico Farmacéutico',
    location: 'Panamá, Panamá',
    type: 'Tiempo Completo',
    description: 'Se busca un químico farmacéutico para supervisar la producción y control de calidad en nuestra planta de manufactura.',
    applyLink: '#',
},
{
    title: 'Asistente Administrativo de Farmacia',
    location: 'Panamá, Panamá',
    type: 'Medio Tiempo',
    description: 'Buscamos un asistente administrativo con experiencia en el sector farmacéutico para apoyar en la gestión de farmacia y atención al cliente.',
    applyLink: '#',
},
{
    title: 'Representante de Ventas Farmacéuticas',
    location: 'Panamá, Panamá',
    type: 'Tiempo Completo',
    description: 'Se necesita un representante de ventas con experiencia en el área farmacéutica para cubrir el territorio nacional.',
    applyLink: '#',
},

  // Puedes agregar más vacantes si es necesario
];

const Vacantes = () => {
  return (
    <div className="vacantes-container">
      <h1>Nuestras Vacantes</h1>
      <div className="vacantes-list">
        {vacantesData.map((vacante, index) => (
          <div className="vacante-card" key={index}>
            <h2 className="vacante-title">{vacante.title}</h2>
            <p className="vacante-location">{vacante.location}</p>
            <p className="vacante-type">{vacante.type}</p>
            <p className="vacante-description">{vacante.description}</p>
            <a href={vacante.applyLink} className="vacante-apply-button">Aplicar Ahora</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vacantes;
