 // src/components/QuienesSomos.js
 import React from 'react';
 import Menu from './Menu'; // Asegúrate de que esta ruta sea correcta
 
 const quienesSomosItems = [
   { label: 'Sobre la Empresa', href: '/quienes-somos/1' },
   { label: 'Historia', href: '/quienes-somos/2' },
   { label: 'Visión', href: '/quienes-somos/3' },
   { label: 'Misión', href: '/quienes-somos/4' },
   { label: 'Valores', href: '/quienes-somos/5' },
   { label: 'Certificaciones', href: '/quienes-somos/6' },
   { label: 'Recomendaciones', href: '/quienes-somos/7' },
 ];
 
 const QuienesSomos = () => {
   return (
     <div>
       <Menu label="Quiénes Somos" items={quienesSomosItems} />
       {/* Aquí puedes agregar contenido adicional para la página "Quiénes Somos" */}
     </div>
   );
 };
 
 export default QuienesSomos;