import React from 'react';
import '../styles/Footer.css'; // Asegúrate de que el CSS esté bien configurado

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h4>Acerca de nosotros</h4>
                <p>Somos una empresa dedicada a la venta de productos farmacéuticos de alta calidad.</p>
              </div>
              <div className="col-md-4">
                <h4>Enlaces rápidos</h4>
                <ul>
                  <li><a href="/">Inicio</a></li>
                  <li><a href="/">Productos</a></li>
                  <li><a href="/">Contacto</a></li>
                  <li><a href="/">Acerca de nosotros</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p>&copy; 2024 Ingenier Beryn. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Botón flotante de WhatsApp */}
      <a
        href="https://wa.me/+50766013659"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-float"
      >
        <img 
          src={require('../assets/img/WS 01.png')} 
          alt="WhatsApp" 
          className="whatsapp-logo"
        />
      </a>
    </>
  );
};

export default Footer;

