// src/components/Mision.js
import React from 'react';
import '../styles/Mision.css'; // Asegúrate de tener este archivo CSS
import logo from '../assets/img/Logo.png'; // Asegúrate de que la ruta sea correcta

const Mision = () => {
  return (
    <div className="mision-container section">
      <div className="mision-content">
        <img src={logo} alt="Beryn Logo" className="mision-logo" />
        <h1>Misión</h1>
        <p>
          Unimos nuestro esfuerzo, buscando mejor calidad de vida para nuestros pacientes, ofreciendo las mejores soluciones farmacéuticas. Cumpliendo con estándares internacionales de distribución y logística, bajo un modelo sostenible, procurando siempre la preservación del medio ambiente.
        </p>
      </div>
    </div>
  );
};

export default Mision;
