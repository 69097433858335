import React from 'react';
import '../styles/servicios.css';
import img1 from '../assets/img/img2.jpg'; // Asegúrate de ajustar la ruta según la ubicación de tu imagen
import successCase1 from '../assets/img/img3.jpg';
import successCase2 from '../assets/img/img2.jpg';

const Logistica = () => {
  return (
    <div className="servicios-section">
      <div className="logistica-card servicio-card">
        <img 
          src={img1} 
          alt="Distribución y Logística" 
          className="servicio-image" 
        />
        <div className="servicio-content">
          <h2 className="proveedor-name">Distribución y Logística</h2>
          <p className="proveedor-description">
            Beryn Farmacéutica comprende la importancia de una cadena de suministro eficiente para el éxito de cualquier empresa farmacéutica.
          </p>
          <ul className="proveedor-list">
            <li><i className="icon-truck"></i> Gestión de la cadena de suministro farmacéutica.</li>
            <li><i className="icon-temperature"></i> Logística de productos sensibles a la temperatura.</li>
            <li><i className="icon-warehouse"></i> Almacenamiento y distribución conforme a las Buenas Prácticas de Distribución (GDP).</li>
            <li><i className="icon-return"></i> Soluciones de logística inversa y manejo de devoluciones.</li>
          </ul>
        </div>
      </div>

      <div className="testimonios-section">
        <h2 className="section-title">Testimonios</h2>
        <div className="testimonios-cards">
          <div className="testimonial-card">
            <p className="testimonial-text">
              "Gracias a Beryn Farmacéutica, hemos optimizado nuestra cadena de suministro, reduciendo costos y mejorando la eficiencia."
            </p>
            <p className="testimonial-author">- Juan Pérez, Gerente de Operaciones en PharmaLogistics</p>
          </div>
          <div className="testimonial-card">
            <p className="testimonial-text">
              "Su experiencia en logística ha sido invaluable para mantener la integridad de nuestros productos sensibles a la temperatura."
            </p>
            <p className="testimonial-author">- María Gómez, Directora de Calidad en BioDistribución</p>
          </div>
        </div>
      </div>

      <div className="casos-exito-section">
        <h2 className="section-title">Casos de Éxito</h2>
        <div className="casos-cards">
          <div className="caso-card">
            <img 
              src={successCase1} 
              alt="Optimización de la Cadena de Suministro" 
              className="caso-image" 
            />
            <h3 className="caso-title">Optimización de la Cadena de Suministro para MedPharm</h3>
            <p className="caso-description">
              Ayudamos a MedPharm a optimizar su cadena de suministro, reduciendo los tiempos de entrega en un 25% y mejorando la satisfacción del cliente.
            </p>
          </div>
          <div className="caso-card">
            <img 
              src={successCase2} 
              alt="Soluciones de Logística Inversa" 
              className="caso-image" 
            />
            <h3 className="caso-title">Soluciones de Logística Inversa para HealthSolutions</h3>
            <p className="caso-description">
              Implementamos un sistema de logística inversa para HealthSolutions que mejoró la eficiencia del manejo de devoluciones en un 30%.
            </p>
          </div>
        </div>
      </div>

      <div className="contacto-section">
        <h2 className="section-title">Contáctenos</h2>
        <form className="contact-form">
          <input type="text" placeholder="Nombre" required />
          <input type="email" placeholder="Correo Electrónico" required />
          <textarea placeholder="Mensaje" required></textarea>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default Logistica;
