import React from 'react';
import Slider from 'react-slick';
import '../styles/HeroSection.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import images
import image1 from '../assets/img/PANAMA.jpg';
import image2 from '../assets/img/img2.jpg';
import image3 from '../assets/img/DISEÑO ORIGINAL.png';
import image4 from '../assets/img/img1.jpg';

const HeroSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true, // Enable fade effect
    cssEase: 'linear', // Smooth transition
    arrows: false, // Hide navigation arrows
  };

  const slides = [
    {
      image: image4,
      alt: 'Escritorio Azul',
      title: 'Bienvenido a Beryn Health Care Farmacéutica',
      description: 'Tu salud, nuestra prioridad.',
    },
    {
      image: image2,
      alt: 'Escritorio Blanco',
      title: 'Innovación en cada paso',
      description: 'Soluciones farmacéuticas avanzadas para tu bienestar.',
    },
    {
      image: image3,
      alt: 'Escritorio Azul',
      title: 'Comprometidos con tu salud',
      description: 'Atención integral y personalizada para cada paciente.',
    },
    {
      image: image1,
      alt: 'Escritorio Azul',
      title: 'Beryn en Ciudad de Panamá',
      description: 'Medicamentos de alta calidad para tu salud. Mejora notablemente tu bienestar con nosotros.',
    },
  ];

  return (
    <section className="hero">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide.image} alt={slide.alt} style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
            <div className="content">
              <h1>{slide.title}</h1>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default HeroSection;