import React from 'react';

const Vitaminas = () => (
  <div>
    <h2>Vitaminas</h2>
    <p>Descripción de las Vitaminas...</p>
  </div>
);

export default Vitaminas;
