// src/components/Recomendaciones.js
import React from 'react';
import '../styles/Historia.css'; // Asegúrate de tener este archivo CSS

const Recomendaciones = () => {
  return (
    <div className="recomendaciones-container section">
      <div className="recomendaciones-content">
        <h1>Recomendaciones de Uso</h1>
        <p>
          Para garantizar la seguridad y eficacia en el uso de nuestros productos, le recomendamos seguir estas indicaciones:
        </p>
        <ul className="recomendaciones-list">
          <li>
            <strong>Consulta Médica:</strong> 
            Siempre consulte a un profesional de la salud antes de iniciar cualquier tratamiento. Es esencial contar con la orientación de un experto para asegurar que el producto sea adecuado para usted.
          </li>
          <li>
            <strong>Lectura de Instrucciones:</strong> 
            Lea detenidamente las instrucciones y advertencias en el etiquetado del producto. Seguir las indicaciones del fabricante es crucial para un uso seguro y efectivo.
          </li>
          <li>
            <strong>Almacenamiento Adecuado:</strong> 
            Almacene los productos en un lugar fresco y seco, lejos de la luz solar directa y fuera del alcance de los niños. Un almacenamiento incorrecto puede afectar la calidad y la seguridad del producto.
          </li>
          <li>
            <strong>Verificación de Fecha de Caducidad:</strong> 
            Asegúrese de verificar la fecha de caducidad antes de utilizar cualquier producto. No utilice productos que hayan expirado.
          </li>
          <li>
            <strong>Evitar la Automedicación:</strong> 
            No se automedique. Aunque nuestros productos son seguros, siempre es mejor utilizarlos bajo la supervisión de un profesional de la salud.
          </li>
        </ul>
        <p>
          Estas recomendaciones están diseñadas para ayudarle a utilizar nuestros productos de manera segura y efectiva, asegurando los mejores resultados posibles.
        </p>
      </div>
    </div>
  );
};

export default Recomendaciones;

