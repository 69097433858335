// src/components/Menu.js
import React from 'react';
import { Link } from 'react-router-dom';

const Menu = ({ label, items }) => {
  return (
    <div className="menu">
      <h2>{label}</h2>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Link to={item.href}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
