import React from 'react';
import '../styles/Vision.css'; // Asegúrate de tener este archivo CSS
import logo from '../assets/img/Logo.png'; // Asegúrate de que la ruta sea correcta

const Vision = () => {
  return (
    <div className="vision-container section">
      <div className="vision-content">
        <img src={logo} alt="Beryn Logo" className="vision-logo" />
        <h1>Visión</h1>
        <p>
          Beryn será reconocida en Latinoamérica como una compañía líder en soluciones Farmacéuticas, con altos estándares de Calidad y seguridad, socialmente responsable, con respeto de los valores corporativos y comerciales ante inversionistas, médicos, pacientes, proveedores y demás aliados estratégicos.
        </p>
      </div>
    </div>
  );
};

export default Vision;
