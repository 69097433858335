import React from 'react';
import '../styles/Noticias.css';
import imagen from '../assets/img/compromiso.jpg'; // Asegúrate de que la imagen esté en la ubicación correcta

const Noticia3 = () => (
  <div className="noticia-standard">
    <div className="noticia-image-container">
      <img
        src={imagen} // Usa la imagen importada
        alt="Noticia reciente sobre tratamiento de obesidad"
        className="noticia-image"
      />
    </div>
    <div className="noticia-info-container">
      <h2 className="noticia-standard-title">Beryn Farmacéutica Lanza Nuevo Tratamiento Contra la Obesidad</h2>
      <p className="noticia-standard-description">
        Beryn Farmacéutica ha dado un paso adelante en la lucha contra la obesidad con el desarrollo de un nuevo tratamiento basado en los últimos avances en medicamentos aprobados por la FDA. Esta innovación busca atacar los mecanismos metabólicos responsables del aumento de peso, proporcionando una solución eficaz para pacientes con obesidad que no responden a tratamientos tradicionales.
      </p>
      <p>
        Investigaciones recientes han demostrado que los medicamentos como los agonistas del receptor GLP-1, ampliamente utilizados para la diabetes tipo 2, también pueden ayudar en el tratamiento de la obesidad. En un estudio realizado por Beryn, se reportaron mejoras significativas en la pérdida de peso y la regulación de la glucosa en sangre, con menos efectos secundarios en comparación con opciones actuales.
      </p>
      <p>
        Este avance es parte de una estrategia más amplia de Beryn para combatir la obesidad, que incluye tanto soluciones farmacológicas como programas educativos que promueven hábitos saludables en colaboración con autoridades de salud pública.
      </p>
      <p className="noticia-standard-description">
        <span className="noticia-date">Publicado el: 5 de septiembre de 2024</span>
        <button className="noticia-standard-button">Leer más</button>
      </p>
    </div>
  </div>
);

export default Noticia3;