// src/components/Clientes.js
import React from 'react';
import '../styles/Clientes.css'; // Asegúrate de que el CSS esté bien estructurado

// Datos de clientes para Beryn Farmacéutica
const clientesData = [
  {
    name: 'María López',
    position: 'Directora de Salud en DFAMR Corp.',
    image: require('../assets/img/Dfamr.png'), // Ruta de la imagen
    testimonial: 'Beryn Farmacéutica ha sido un socio clave en el desarrollo de nuestros programas de salud, proporcionando medicamentos de alta calidad y un servicio excepcional.'
  },
  {
    name: 'José Martínez',
    position: 'Jefe de Compras en MDI-MEDIC Ltd.',
    image: require('../assets/img/mdi.png'),
    testimonial: 'La fiabilidad y la eficacia de los productos de Beryn nos han permitido ofrecer mejores soluciones a nuestros clientes. Su atención al cliente es insuperable.'
  },
  
  // Puedes agregar más clientes si es necesario
];

const Clientes = () => {
  return (
    <div className="clientes-container">
      <h1>Lo Que Dicen Nuestros Clientes</h1>
      <div className="clientes-list">
        {clientesData.map((cliente, index) => (
          <div className="cliente-card" key={index}>
            <img src={cliente.image} alt={cliente.name} className="cliente-image" />
            <div className="cliente-info">
              <h2 className="cliente-name">{cliente.name}</h2>
              <p className="cliente-position">{cliente.position}</p>
              <blockquote className="cliente-testimonial">
                "{cliente.testimonial}"
              </blockquote>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clientes;
