// src/components/Historia.js
import React from 'react';
import '../styles/Historia.css';

const Historia = () => {
  return (
    <div className="historia-container">
      <header className="historia-header">
        <h1>Historia de la Empresa</h1>
      </header>
      <section className="historia-content">
        <div className="section-1">
          <h2>Los Comienzos</h2>
          <p>
            Beryn Farmacéutica inició con una visión clara: mejorar la calidad de vida de las personas a través de soluciones farmacéuticas innovadoras y accesibles. Desde el principio, la empresa se enfocó en ofrecer productos de alta calidad, comprometidos con la salud y el bienestar de sus pacientes. Con una pequeña pero dedicada fuerza laboral, Beryn empezó a ganar reconocimiento en el mercado local.
          </p>
        </div>
        <div className="section-2">
          <h2>Crecimiento y Expansión</h2>
          <p>
            Gracias a la confianza depositada por nuestros clientes y la constante innovación, Beryn Farmacéutica comenzó a expandirse rápidamente. Incorporamos nuevos productos a nuestro portafolio y nos aventuramos en nuevos mercados, estableciendo oficinas y puntos de distribución en diferentes regiones. El crecimiento no solo fue geográfico, sino también tecnológico, implementando avanzados sistemas de control de calidad y logística que garantizan la eficacia de nuestros productos.
          </p>
        </div>
        <div className="section-3">
          <h2>Hoy en Día</h2>
          <p>
            Actualmente, Beryn Farmacéutica es un referente en la industria farmacéutica latinoamericana. Nos enorgullece ofrecer una amplia gama de productos que cumplen con los más altos estándares de calidad internacional. Nuestra misión sigue siendo la misma: proporcionar soluciones farmacéuticas que mejoren la vida de las personas, con un enfoque en la sostenibilidad y la preservación del medio ambiente. Continuamos trabajando con pasión y dedicación, siempre buscando nuevas formas de innovar y crecer.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Historia;

