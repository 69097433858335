import React from 'react';
import '../styles/Noticias.css';
import imagen from '../assets/img/diabetes.jpg'; // Asegúrate de que la imagen esté en la ubicación correcta

const Noticia2 = () => (
  <div className="noticia-standard">
    <div className="noticia-image-container">
      <img
        src={imagen} // Usa la imagen importada
        alt="Noticia médica reciente sobre diabetes"
        className="noticia-image"
      />
    </div>
    <div className="noticia-info-container">
      <h2 className="noticia-standard-title">Avance Importante en el Tratamiento de la Diabetes Tipo 2</h2>
      <p className="noticia-standard-description">
        Investigadores han descubierto un nuevo tratamiento que podría revolucionar el manejo de la diabetes tipo 2. El nuevo fármaco, que ha mostrado resultados prometedores en ensayos clínicos, ofrece una alternativa más eficaz y con menos efectos secundarios que los tratamientos actuales.
      </p>
      <p>
        En un reciente estudio publicado en el Journal of Diabetes Research, se detallan los resultados de un ensayo clínico con más de 1,000 pacientes. El fármaco, conocido como GlucoMed, actúa sobre los mecanismos metabólicos específicos que causan la diabetes tipo 2, lo que permite un control más preciso de los niveles de azúcar en sangre.
      </p>
      <p>
        Los investigadores están entusiasmados con los resultados preliminares, que sugieren que GlucoMed podría ser una opción viable para pacientes que no responden bien a los tratamientos actuales. Aún se requieren más estudios para confirmar la seguridad a largo plazo, pero los primeros signos son extremadamente alentadores.
      </p>
      <p className="noticia-standard-description">
        <span className="noticia-date">Publicado el: 5 de septiembre de 2024</span>
        <button className="noticia-standard-button">Leer más</button>
      </p>
    </div>
  </div>
);

export default Noticia2;