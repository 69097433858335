import React from 'react';
import '../styles/servicios.css';

const Desarrollo = () => {
  return (
    <div className="servicios-section">
      <div className="desarrollo-card servicio-card">
        <h2 className="proveedor-name">Desarrollo de Productos</h2>
        <p className="proveedor-description">
          En Beryn Farmacéutica, nos especializamos en el desarrollo de productos innovadores que satisfacen las crecientes demandas del mercado farmacéutico.
        </p>
        <ul className="proveedor-list">
          <li>Investigación y desarrollo de nuevas formulaciones.</li>
          <li>Estudios de viabilidad y pruebas de concepto.</li>
          <li>Desarrollo de métodos analíticos y validación.</li>
          <li>Optimización de procesos de producción y escalado industrial.</li>
        </ul>
      </div>

      <div className="testimonios-section">
        <h2 className="section-title">Testimonios</h2>
        <div className="testimonios-cards">
          <div className="testimonial-card">
            <p className="testimonial-text">
              "Gracias a Beryn Farmacéutica, pudimos desarrollar una formulación innovadora que superó nuestras expectativas."
            </p>
            <p className="testimonial-author">- Dr. Carlos Martínez, Director de I+D en PharmaLab</p>
          </div>
          <div className="testimonial-card">
            <p className="testimonial-text">
              "El equipo de Beryn es altamente competente en cada fase del desarrollo de productos. Los recomendaría sin dudarlo."
            </p>
            <p className="testimonial-author">- Ana López, Gerente de Producción en BioTech</p>
          </div>
        </div>
      </div>

      <div className="casos-exito-section">
        <h2 className="section-title">Casos de Éxito</h2>
        <div className="casos-cards">
          <div className="caso-card">
            <h3 className="caso-title">Innovación en Formulación para HealthCorp</h3>
            <p className="caso-description">
              Colaboramos con HealthCorp para desarrollar una nueva formulación que redujo los costos de producción en un 20%.
            </p>
          </div>
          <div className="caso-card">
            <h3 className="caso-title">Optimización de Escalado para NutraPharma</h3>
            <p className="caso-description">
              Ayudamos a NutraPharma a escalar su proceso de producción, incrementando la eficiencia en un 35% mientras manteníamos la calidad del producto.
            </p>
          </div>
        </div>
      </div>

      <div className="contacto-section">
        <h2 className="section-title">Contáctenos</h2>
        <form className="contact-form">
          <input type="text" placeholder="Nombre" required />
          <input type="email" placeholder="Correo Electrónico" required />
          <textarea placeholder="Mensaje" required></textarea>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default Desarrollo;
